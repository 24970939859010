import React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AbleSenseHero from "../components/able-sense-page/able-sense-hero";
import AbleSenseContent from "../components/able-sense-page/able-sense-content";

const AbleSensePage = ({ data }) => {
  const { ableSense } = data.takeshape;

  if (
    (!ableSense.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!ableSense.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }

  return (
    <Layout>
      <Seo
        title={ableSense.pageTitle}
        description="Half Helix expands Shopify service offering with Able Sense acquisition"
      />
      <div className="main--wrap">
        <AbleSenseHero
          title={ableSense.title}
          heroDesktopImage={ableSense.heroDesktopImage}
          heroMobileImage={ableSense.heroMobileImage}
        />
        <AbleSenseContent
          content={ableSense.content}
          publishDate={ableSense.publishDate}
        />
      </div>
    </Layout>
  );
};

export default AbleSensePage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      ableSense: getAbleSense {
        content(format: html)
        enableOnStaging
        enabled
        heroDesktopImage {
          caption
          path
          sourceUrl
          title
          metadata {
            PixelWidth
            PixelHeight
          }
        }
        heroMobileImage {
          caption
          path
          sourceUrl
          title
          metadata {
            PixelWidth
            PixelHeight
          }
        }
        pageTitle
        title
        publishDate
      }
    }
  }
`;
