import React from "react";

const AbleSenseContent = ({ content, publishDate }) => {
  return (
    <div className="container container--bordered container--overlay">
      <div className="able-sense-content">
        <div className="able-sense-content__main rich-text">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>

          <div className="able-sense-content__published">
            <p>Published: {publishDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbleSenseContent;
